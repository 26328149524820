import * as yup from "yup";
import {
  fileValidation,
  numberValidation,
  phoneNumberValidation,
  requireValidation,
} from "../../../../helpers/validations";

export const basicDetailSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required ")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  mobile: phoneNumberValidation({ name: "Mobile Number" }).required(
    "Mobile Number is required"
  ),
  email: yup
    .string()
    .required("Email ID is Required")
    // eslint-disable-next-line
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter a Valid Email ID"),
  dob: requireValidation({ name: "Date Of Birth" }),
  city: requireValidation({ name: "City" }),
  pincode: yup
    .string()
    .required("Pin Code is Required")
    .matches(/^[1-9][0-9]{5}$/, "Enter a Valid Pincode"),
  pan: yup
    .string()
    .required("Pan is required")
    .matches(
      /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
      "Pan Number is Incorrect"
    ),
  adhaar: yup
    .string()
    .required("Aadhar Number is required")
    .matches(
      /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/,
      "Aadhaar Number is incorrect"
    ),
  residenceAddress: requireValidation({ name: "Residence Address" }),
});

export const AccountDetailsSchema = yup.object().shape({
  ifsc: yup
    .string()
    .required("IFSC CODE is required")
    .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, "IFSC code is incorrect"),
  accountNumber: numberValidation({name :"Account Number"}),
  accountName: requireValidation({ name: "Acount Holder Name" }),
});
export const LoanDetailsSchema = yup.object().shape({
  loanAmount: numberValidation({name :"Loan Amount"}),
  loanPurpose: requireValidation({ name: "Loan Purpose" }),
  loanTime: numberValidation({name: "Loan Duration is required"}),
});

export const UploadFilesSchema = yup.object().shape({
  // adhaarBack: fileValidation({ name: "Adhaar Card" }),
  // adhaarFront: fileValidation({ name: "Adhaar Card" }),
  // bankStatement: fileValidation({ name: "Bank Statement" }),
  // panProof: fileValidation({ name: "Pan Card" }),
  // salarySlip: fileValidation({ name: "Salary Slip" }),
  // residenceProof: fileValidation({ name: "Residence Proof" }),
});
