import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../../../context/StateContext";

const Footer = () => {
  const { DarkModeStatus, darkModeHandler, openSideBar, handleSideBar } =
    useContext(Context);
  console.log("working ", DarkModeStatus, openSideBar);
  return (
    // <Context.Consumer>
    //   {({ openSideBar }) => {
    //     return (
    <div
      className={`content ${!DarkModeStatus ? "light" : ""} ${
        openSideBar ? "" : "open"
      }`}
    >
      <div className="container-fluid pt-4 px-4">
        <div
          className={`${
            !DarkModeStatus ? "bg-white" : "bg-secondary"
          } rounded-top p-4`}
        >
          <div className="row">
            <div className="col-12 col-sm-6 text-center text-sm-start">
              &copy;
              <span className="text-footer">
                <Link to="/">OCPL FINANCE</Link>
              </span>
              , All Right Reserved.
            </div>
            <div className="col-12 col-sm-6 text-center text-sm-end">
              Made with ❤ By <a href="https://ocpl.tech/">OCPL</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    //     );
    //   }}
    // </Context.Consumer>
  );
};

export default Footer;
