import React, { useContext, useEffect, useState } from "react";
// import { ErrorMessage, Formik } from "formik";
import { mixed, object, string } from "yup";
import "./index.scss";
import { Context } from "../../../../../context/StateContext";
import { useNavigate, useParams } from "react-router-dom";
import { create } from "ipfs-http-client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import http from "../../../../services/httpservice.js";

const CustomErrorMessage = ({ children }) => (
  <span style={{ color: "red", fontSize: "14px" }}>{children}</span>
);

const validationSchema = object().shape({
  addproof1: mixed().required("File is required"),
  addproof2: mixed().required("File is required"),

  workproof1: mixed().required("File is required"),

  workproof2: mixed().required("File is required"),

  remark: string().required("Remark is required"),
});

const AgentForm = () => {
  const [serverList, setServerList] = useState([
    "http://192.168.29.61:5000",
    "http://192.168.29.61:5001",
    "http://192.168.29.61:5002",
  ]);
  const [isRequestSent, setIsRequestSent] = useState(false);
  // const [activeServer, setActiveServer] = useState(0);
  const [verifiedServer, setVerifiedServer] = useState("");
  // console.log("verified servers", verifiedServer);
  useEffect(() => {
    const sendApiRequest = async (url) => {
      try {
        const response = await fetch(url);
        console.log(`resonse from url ${url}`, response);
        // const data = await response.json();
        // console.log(`data from url ${url}`, data);

        if (response.status === 200) {
          console.log(`API request sent to ${url}`);
          setIsRequestSent(true); // set isRequestSent to true if API call is successful
          return true; // return true if API call is successful
        } else {
          // console.error(`Error in API response from ${url}: ${data.error}`);
          return false; // return false if API call returns an error
        }
      } catch (error) {
        // console.error(`Error sending API request to ${url}: ${error}`);
        return false; // return false if there's an error sending the API request
      }
    };

    const sendRequestToServers = async () => {
      for (const url of serverList) {
        const isServerHealthy = await sendApiRequest(url);
        if (isServerHealthy) {
          setVerifiedServer(url);
          break; // stop iterating through servers if a healthy server is found
        }
      }
    };

    sendRequestToServers();
  }, [serverList]);
  // console.log("verified servers2", verifiedServer);
  const apiEndpoint = `${verifiedServer}/api/mine2`;
  const { clientName } = useParams();
  const navigate = useNavigate();

  const defaultValues = {
    addproof1: null,
    addproof2: null,
    workproof1: null,
    workproof2: null,
    remark: "",
  };
  const { DarkModeStatus, openSideBar } = useContext(Context);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });

  const handleDrop = async (file, filename) => {
    const ipfs = create("http://127.0.0.1:5001");
    const fileContent = await file.arrayBuffer();
    const fileAdded = await ipfs.add({ content: fileContent });
    const cid = fileAdded.cid.toString();
    setValue(filename, cid);
  };
  const resetForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };

  const onSubmit = async (data) => {
    try {
      const resp = await http.post(apiEndpoint, { data, email: clientName });
      // console.log("resp", resp);
      resetForm();
      navigate("/corporate/isAgent/agent");
    } catch (err) {
      console.log("error", err.message);
      
    }
  };

  return (
    <div
      className={`content content ${!DarkModeStatus ? "light" : ""} ${
        openSideBar ? "" : "open"
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid pt-4 px-4">
          <div className="col">
            <div
              className={`${
                !DarkModeStatus ? "bg-white text-dark" : "bg-secondary"
              } rounded h-100 p-4`}
            >
              <button
                className="btn-upload my-3"
                onClick={() => navigate("/corporate/isAgent/agent")}
              >
                {" "}
                {"<--- "}Back to Table
              </button>
              <h1>Client Name: {clientName}</h1>
              <h6 className={`mb-4 ${!DarkModeStatus ? "text-dark" : ""}`}>
                Upload the Documents Here
              </h6>
              <div className="mb-3">
                <div className="row g-3 align-items-center mb-4">
                  <div className="col">
                    <label htmlFor="formFile" className="form-label">
                      Address Proof(1):
                    </label>
                  </div>
                  <div className="col-12 col-lg-4">
                    <input
                      // {...register("addproof1")}
                      className="form-control bg-dark"
                      type="file"
                      // id="addproof1"
                      // name="addproof1"
                      onChange={(event) =>
                        handleDrop(event.currentTarget.files[0], "addproof1")
                      }
                    />
                  </div>
                  <div className="col">
                    {errors.addproof1 && (
                      <CustomErrorMessage>
                        {errors.addproof1.message}
                      </CustomErrorMessage>
                    )}
                  </div>
                </div>
                <div className="row g-3 align-items-center mb-4">
                  <div className="col">
                    <label htmlFor="formFile" className="form-label">
                      Address Proof(2):
                    </label>
                  </div>
                  <div className="col-12 col-lg-4">
                    <input
                      // {...register("addproof2")}
                      className="form-control bg-dark"
                      type="file"
                      // id="addproof2"
                      // name="addproof2"
                      onChange={(event) =>
                        handleDrop(event.currentTarget.files[0], "addproof2")
                      }
                    />
                  </div>
                  <div className="col">
                    {errors.addproof2 && (
                      <CustomErrorMessage>
                        {errors.addproof2.message}
                      </CustomErrorMessage>
                    )}
                  </div>
                </div>
                <div className="row g-3 align-items-center mb-4">
                  <div className="col">
                    <label htmlFor="formFile" className="form-label">
                      Work Proof(1):
                    </label>
                  </div>
                  <div className="col-12 col-lg-4">
                    <input
                      // {...register("workproof1")}
                      className="form-control bg-dark"
                      type="file"
                      // id="workproof1"
                      // name="workproof1"
                      onChange={(event) =>
                        handleDrop(event.currentTarget.files[0], "workproof1")
                      }
                    />
                  </div>
                  <div className="col">
                    {errors.workproof1 && (
                      <CustomErrorMessage>
                        {errors.workproof1.message}
                      </CustomErrorMessage>
                    )}
                  </div>
                </div>
                <div className="row g-3 align-items-center mb-4">
                  <div className="col">
                    <label htmlFor="formFile" className="form-label">
                      Work Proof(2):
                    </label>
                  </div>
                  <div className="col-12 col-lg-4">
                    <input
                      // {...register("workproof2")}
                      className="form-control bg-dark"
                      type="file"
                      // id="workproof2"
                      // name="workproof2"
                      onChange={(event) =>
                        handleDrop(event.currentTarget.files[0], "workproof2")
                      }
                    />
                  </div>
                  <div className="col">
                    {errors.workproof2 && (
                      <CustomErrorMessage>
                        {errors.workproof2.message}
                      </CustomErrorMessage>
                    )}
                  </div>
                </div>
                <div className="row g-3 align-items-center mb-4">
                  <div className="col">
                    <label htmlFor="formFile" className="form-label">
                      Remarks:
                    </label>
                  </div>
                  <div className="col-12 col-lg-4">
                    <input
                      {...register("remark")}
                      className="form-control"
                      type="text"
                      id="remark"
                      name="remark"
                      // onChange={handleChange("remark")}
                    />
                  </div>
                  <div className="col">
                    {errors.remark && (
                      <CustomErrorMessage>
                        {errors.remark.message}
                      </CustomErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="m-n2">
                <button
                  type="submit"
                  className="btn-upload m-2"
                  // disabled={!isValid}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AgentForm;
