import { Fragment } from "react";
import "./dropzone.scss";
import { Col, FormFeedback, Row } from "reactstrap";
import Dropzone from "react-dropzone";
import { useFormContext } from "react-hook-form";
// eslint-disable-next-line
import { create } from "ipfs-http-client";

const UploadFiles = () => {
  const {
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext();

  const DropzoneHandler = ({ formKey }) => {
    const handleDrop = async (file) => {
      const ipfs = create("http://127.0.0.1:5001");
      const fileContent = await file.arrayBuffer();
      const fileAdded = await ipfs.add({ content: fileContent });
      const cid = fileAdded.cid.toString();
      setValue(formKey, cid);
      trigger(formKey);
    };
    return (
      <>
        <Dropzone
          onDrop={(file) => {
            handleDrop(file[0]);
          }}
          // accept={"png/*"}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              {watch(formKey) ? (
                <p className="mt-4">
                  {/* {watch(formKey).name} */}
                  File Uploaded
                </p>
              ) : (
                <>
                  {" "}
                  <input {...getInputProps()} />
                  <div>
                    <span className="mdi mdi-upload text-primary fs-3">
                      <br />
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </Dropzone>
        {errors[formKey] && (
          <FormFeedback role="alert" className="d-block">
            {errors[formKey].message}
          </FormFeedback>
        )}
      </>
    );
  };

  return (
    <Fragment>
      <Row className="align-items-center">
        <p className="fs-2 fw-bolder ">
          <span>Document</span>s Upload
        </p>
        <Col lg={4}>
          <p className="gray fw-bolder">Pan Card </p>
        </Col>
        <Col lg={8}>
          <DropzoneHandler formKey="panProof" />
        </Col>

        <Col lg={4}>
          {" "}
          <p className="gray fw-bolder">Adhaar Card </p>
        </Col>
        <Col lg={4}>
          <DropzoneHandler formKey="adhaarFront" />
        </Col>
        <Col lg={4}>
          <DropzoneHandler formKey="adhaarBack" />
        </Col>
        <Col lg={4}>
          {" "}
          <p className="gray fw-bolder">Bank Statement</p>
        </Col>
        <Col lg={8}>
          <DropzoneHandler formKey="bankStatement" />
        </Col>
        <Col lg={4}>
          {" "}
          <p className="gray fw-bolder">Salary Slip</p>
        </Col>
        <Col lg={8}>
          <DropzoneHandler formKey="salarySlip" />
        </Col>
        <Col lg={4}>
          {" "}
          <p className="gray fw-bolder">Residence Proof </p>
        </Col>
        <Col lg={8}>
          <DropzoneHandler formKey="residenceProof" />
        </Col>
      </Row>
    </Fragment>
  );
};

export default UploadFiles;
