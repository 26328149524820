import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import { Context } from "../../../../../context/StateContext";
import axios from "axios";
import http from "../../../../services/httpservice.js";

const Clients = () => {
  let clientsMoreThan = [];
  let clientsLessThan = [];
  let clientsdocuments1 = [];
  const [clientsMoreThanArray, setClientsMoreThanArray] = useState([]);
  const [clientsLessThanArray, setClientsLessThanArray] = useState([]);
  const [clientDocuments, setClientDocuments] = useState({});
  const [blockchain2Array, setBlockchain2Array] = useState([]);
  const [status, setStatus] = useState("Upload Pending");
  const serverList = [
    "http://192.168.29.61:5000/api",
    "http://192.168.29.61:5001/api",
    "http://192.168.29.61:5002/api",
  ];
  const [activeServer, setActiveServer] = useState(0);
  const handleSetClient = (customerEmail) => {
    for (let i = 0; i < blockchain2Array.length; i++) {
      if (blockchain2Array[i].data.email) {
        if (blockchain2Array[i].data.email === customerEmail) {
          // setClientDocuments(blockchain2Array[i].data.data);
          clientsdocuments1.push(blockchain2Array[i].data.data);
        }
      }
    }
    setClientDocuments(clientsdocuments1);
  };
  const getStatus = (customerEmail) => {
    // console.log("yes we are here4");
    if (blockchain2Array.length === 1) {
      return "Upload Pending";
    } else {
      for (let i = 1; i < blockchain2Array.length; i++) {
        if (blockchain2Array[i].data.email) {
          // console.log("yes we are here3");
          if (blockchain2Array[i].data.email === customerEmail) {
            // console.log("yes we are here");
            return "Documents Uploaded";
          }
        } else {
          return "Upload Pending";
        }
      }
    }
  };
  const getDate = (timeStamp) => {
    const date = new Date(timeStamp);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleString(undefined, options);
  };
  const blockchain2Data = async () => {
    try {
      const response = await axios.get(
        `${serverList[activeServer]}/blockchain2`
      );
      setBlockchain2Array(response.data);
      // console.log("response blockchain2", response);
    } catch (err) {
      console.log("error in getting the bc2 data", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
  };

  const loanDetails = async () => {
    try {
      const response = await axios.get(
        `${serverList[activeServer]}/blockchain1`
      );
      const responseArray = response.data;
      // console.log("response array", responseArray);
      clientsLessThan = responseArray.filter(
        (element) => element.data.decoData?.loanAmount <= 20000
      );
      clientsMoreThan = responseArray.filter(
        (element) => element.data.decoData?.loanAmount > 20000
      );
      // responseArray.map((element) => {
      //   if (element.data.decoData) {
      //     console.log("hey we are here");
      //     if (element.data.decoData.loanAmount > 20000) {
      //       clientsMoreThan.push(element);
      //     }
      //     if (element.data.decoData.loanAmount <= 20000) {
      //       console.log("hey we are here2");
      //       clientsLessThan.push(element);
      //     }
      //   }
      //   // console.log("clientslessthan3", clientsLessThan);
      // });
      setClientsLessThanArray(clientsLessThan);
      setClientsMoreThanArray(clientsMoreThan);
    } catch (err) {
      console.log("error", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
  };
  useEffect(() => {
    loanDetails();
    blockchain2Data();
    //eslint-disable-next-line
  }, [activeServer]);
  const apiEndpoint = `${serverList[activeServer]}/mine3`;
  // useEffect(() => {
  //   handlePayment();
  // }, [activeServer]);
  const handlePayment = async (userData) => {
    let loanAmount = userData.loanAmount;
    let email = userData.email;
    let name = userData.name;
    let trn = "";
    const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const length = 15;
    for (let i = 0; i < length; i++) {
      trn += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    let today = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = today.toLocaleDateString(undefined, options);
    // console.log(formattedDate);
    try {
      const resp = await http.post(apiEndpoint, {
        loanAmount,
        trn,
        email,
        formattedDate,
        name,
      });
      console.log("bc3 response on clicking pay now by admin", resp);
    } catch (err) {
      console.log("error", err.message);
      if (err.message === "Network Error") {
        console.log("in catch block");
        setActiveServer((activeServer + 1) % serverList.length);
        const resp = await http.post(apiEndpoint, {
          loanAmount,
          trn,
          email,
          formattedDate,
          name,
        });
      }
    }
  };
  const { DarkModeStatus, openSideBar } = useContext(Context);
  const [currentUser, setCurrentUser] = useState({});
  // console.log("clientsmorethan", clientsMoreThanArray);
  // console.log("clientslessthan11111", clientsLessThanArray);

  const handleDownload = (cid) => {
    fetch(`https://ipfs.io/ipfs/${cid}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "filename.jpg";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error(error));
  };

  return (
    <div
      className={`content client ${!DarkModeStatus ? "light" : ""} ${
        openSideBar ? "" : "open"
      }`}
    >
      <div className="col-12">
        <div className="container-fluid pt-4 px-4">
          <div
            className={`${
              !DarkModeStatus ? "bg-white" : "bg-secondary"
            } rounded h-100 p-4`}
          >
            <h6 className="mb-4">Clients Table</h6>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr
                    className={`${
                      !DarkModeStatus ? "text-dark" : "text-white"
                    } ms-3`}
                  >
                    <th scope="col">Name</th>
                    <th scope="col">Agent</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Action
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      View Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clientsMoreThanArray.map((element, key) => {
                    return (
                      <tr
                        key={key}
                        className={`${!DarkModeStatus ? "text-dark" : ""}`}
                      >
                        <td>{element.data.decoData?.name}</td>
                        <td>{element.data.decoData?.agentAssigned}</td>
                        <td>
                          {blockchain2Array
                            ? getStatus(element.data.decoData?.email)
                            : null}
                        </td>
                        <td>{element.data.decoData?.loanAmount}</td>
                        <td>
                          {element.data.decoData && getDate(element.timestamp)}
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handlePayment(element.data?.decoData)
                            }
                            type="button"
                            className="btn-pay"
                            // disabled={getStatus !== "Documents Uploaded"}
                          >
                            Pay
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="btn-pay"
                            onClick={() => {
                              setCurrentUser(element.data?.decoData);
                              handleSetClient(element.data?.decoData.email);
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-4 px-4">
          <div
            className={`${
              !DarkModeStatus ? "bg-white" : "bg-secondary"
            } rounded h-100 p-4`}
          >
            <h6 className="mb-4">Clients Table Less Than ₹20,000</h6>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr
                    className={`${
                      !DarkModeStatus ? "text-dark" : "text-white"
                    } ms-3`}
                  >
                    <th scope="col">Name</th>
                    {/* <th scope="col">Status</th> */}
                    <th scope="col">Amount</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {clientsLessThanArray.map((element, key) => {
                    return (
                      <tr
                        key={key}
                        className={`${!DarkModeStatus ? "text-dark" : ""}`}
                      >
                        <td>{element.data.decoData?.name}</td>
                        <td>{element.data.decoData?.loanAmount}</td>
                        <td>
                          {element.data.decoData && getDate(element.timestamp)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Modal  */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div
                class={`modal-header ${
                  !DarkModeStatus ? "bg-darkgrey" : "bg-dark"
                } rounded h-100 p-4 bg-gradient`}
              >
                <h5 class="modal-title" id="exampleModalLabel">
                  Welcome to Client Dashboard
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container pt-4 px-4">
                  <div class="row rounded align-items-center justify-content-between mx-0 ">
                    <div className="col-12 mb-3">
                      <div
                        class={`${
                          !DarkModeStatus
                            ? "bg-white text-dark"
                            : "bg-secondary"
                        } rounded h-100 p-4`}
                      >
                        <h4 class="mb-4">Testimonial</h4>
                        <div class="owl-carousel testimonial-carousel">
                          <div class="testimonial-item text-center">
                            <img
                              class="img-fluid rounded-circle mx-auto mb-4"
                              src="https://www.shutterstock.com/image-vector/man-character-face-avatar-glasses-600w-562077406.jpg"
                              style={{ width: "100px", height: "100px" }}
                              alt="profile"
                            />
                            <h5 class="mb-1">{currentUser.name}</h5>
                            {/* <p>{currentUser.profession}</p> */}
                            <p class="mb-0">
                              <div className="email-phoneNumber">
                                <p>
                                  <BiEnvelope size={20} color="#7188BE" />{" "}
                                  {currentUser.email}
                                </p>
                                <p>
                                  <BiPhone size={20} color="#7188BE" /> +91{" "}
                                  {currentUser.mobile}
                                </p>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-xl-6 mb-3">
                      <div
                        class={`${
                          !DarkModeStatus
                            ? "bg-white text-dark"
                            : "bg-secondary"
                        } rounded h-100 p-4`}
                      >
                        <h5 class="mb-4">Personal Details</h5>
                        <hr />
                        <table class="table">
                          <tbody>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Account No</td>
                              <td>{currentUser.accountNumber}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>IFSC Code</td>
                              <td>{currentUser.ifsc}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Pan</td>
                              <td>{currentUser.pan}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Aadhaar</td>
                              <td>{currentUser.adhaar}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>D.O.B</td>
                              <td>{currentUser.dob}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-sm-12 col-xl-6 mb-3">
                      <div
                        class={`${
                          !DarkModeStatus
                            ? "bg-white text-dark"
                            : "bg-secondary"
                        } rounded h-100 p-4`}
                      >
                        <h5 class="mb-4">Loan Details</h5>
                        <hr />
                        <table class="table">
                          <tbody>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Agent Name</td>
                              <td>{currentUser.agentAssigned}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Purpose</td>
                              <td>{currentUser.loanPurpose}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Status</td>
                              <td>{getStatus(currentUser.email)}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Amount</td>
                              <td>{currentUser.loanAmount}</td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>City</td>
                              <td>{currentUser.city}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-xl-6 mb-3"
                      style={{ height: "343px" }}
                    >
                      <div
                        class={`${
                          !DarkModeStatus
                            ? "bg-white text-dark"
                            : "bg-secondary"
                        } rounded h-100 p-4`}
                      >
                        <h5 class="mb-4">Download Client's Documents</h5>
                        <hr />
                        <table class="table">
                          <tbody>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Address Proof:</td>
                              <td className="">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(currentUser.residenceProof)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Pan Card:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(currentUser.panProof)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Aadhaar Card:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDownload(currentUser.adhaarFront);
                                    handleDownload(currentUser.adhaarBack);
                                  }}
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Salary Slip:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(currentUser.salarySlip)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Bank Statement:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(currentUser.bankStatement)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 col-xl-6 mb-3"
                      style={{ height: "343px" }}
                    >
                      <div
                        class={`${
                          !DarkModeStatus
                            ? "bg-white text-dark"
                            : "bg-secondary"
                        } rounded h-100 p-4`}
                      >
                        <h5 class="mb-4">Download Client's Documents</h5>
                        <hr />
                        <table class="table">
                          <tbody>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Residential Proof 1:</td>
                              <td className="">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(clientDocuments.addproof1)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Residential Proof 2:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(clientDocuments.addproof2)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Working Proof 1:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(clientDocuments.workproof1)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr
                              className={`${
                                !DarkModeStatus ? "text-dark" : ""
                              }`}
                            >
                              <td>Working Proof 2:</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(clientDocuments.workproof2)
                                  }
                                  className="btn-pay"
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
