import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../../../context/StateContext";
import axios from "axios";
import jwtDecode from "jwt-decode";

const Agent = () => {
  const { DarkModeStatus, openSideBar } = useContext(Context);
  let clientsMoreThan = [];
  const [clientsMoreThanArray, setClientsMoreThanArray] = useState([]);
  const token = jwtDecode(localStorage.getItem("corporatetoken"));
  const name = token.user.name;
  const email = token.user.email;
  let blockchain2Array1 = [];
  const [blockchain2Array, setBlockchain2Array] = useState([]);
  const serverList = [
    "http://192.168.29.61:5000/api",
    "http://192.168.29.61:5001/api",
    "http://192.168.29.61:5002/api",
  ];
  const [activeServer, setActiveServer] = useState(0);
  const getStatus = (customerEmail) => {
    // console.log("yes we are here4");
    // console.log("blockchain2Array", blockchain2Array);
    if (blockchain2Array.length === 1) {
      return "Upload Pending";
    } else {
      for (let i = 1; i < blockchain2Array.length; i++) {
        // console.log("yes we are here5");

        if (blockchain2Array[i].data.email) {
          // console.log("yes we are here3");
          if (blockchain2Array[i].data.email === customerEmail) {
            // console.log("yes we are here");
            return "Documents Uploaded";
          }
        } else {
          return "Upload Pending2";
        }
      }
    }
  };
  const blockchain2Data = async () => {
    try {
      // console.log("yes we are here 6");
      const response = await axios.get(
        `${serverList[activeServer]}/blockchain2`
      );
      // setBlockchain2Array(response.data.existingBlockchain);
      for (let i = 0; i < response.data.length; i++) {
        blockchain2Array1.push(response.data[i]);
      }

      // console.log("response blockchain2", response);
    } catch (err) {
      console.log("error in getting the bc2 data", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
    setBlockchain2Array(blockchain2Array1);
  };

  const userDetailsAgent = async () => {
    try {
      const response = await axios.get(
        `${serverList[activeServer]}/blockchain1`
      );
      const responseArray = response.data;
      // console.log("response array for More than", responseArray);
      clientsMoreThan = responseArray.filter(
        (element) =>
          element.data.decoData?.loanAmount > 20000 &&
          element.data.decoData.agentAssigned === name
      );

      setClientsMoreThanArray(clientsMoreThan);
      // console.log("clients more than array", clientsMoreThanArray);
    } catch (err) {
      console.log("error in fetching bc 1 data", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
  };
  useEffect(() => {
    userDetailsAgent();
    blockchain2Data();
    //eslint-disable-next-line
  }, [activeServer]);

  return (
    <div
      className={`content content ${!DarkModeStatus ? "light" : ""} ${
        openSideBar ? "" : "open"
      }`}
    >
      <div className="container-fluid pt-4 px-4 min-vh-100">
        <div
          className={`${
            !DarkModeStatus ? "bg-white" : "bg-secondary"
          } rounded h-100 p-4`}
        >
          <h6 className="mb-4">New Loan Request</h6>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr
                  className={`${
                    !DarkModeStatus ? "text-dark" : "text-white"
                  } ms-3`}
                >
                  <th scope="col">Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Pan</th>
                  <th scope="col">Aadhaar</th>
                  <th scope="col">Documents</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {clientsMoreThanArray.map((element, key) => {
                  return (
                    <tr
                      key={key}
                      className={`${!DarkModeStatus ? "text-dark" : ""}`}
                    >
                      <td>{element.data.decoData?.name}</td>
                      <td>{element.data.decoData?.residenceAddress}</td>
                      <td>{element.data.decoData?.mobile}</td>
                      <td>{element.data.decoData?.pan}</td>
                      <td>{element.data.decoData?.adhaar}</td>
                      <td>
                        <Link
                          to={{
                            pathname: `/corporate/isAgent/agentForm/${element.data.decoData?.email}`,
                          }}
                        >
                          <div className="">Upload</div>
                        </Link>
                      </td>
                      <td>
                        {blockchain2Array
                          ? getStatus(element.data.decoData?.email)
                          : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    //         );
    //       }}
    //     </Context.Consumer>
    //     <Footer />
    //     </Context.Provider>
    // </div>
  );
};

export default Agent;
