import { Form } from "reactstrap";
import NextButton from "./action-button/next-button";
import AccountDetails from "./steps/account-details";
import BasicDetails from "./steps/basic-details";
import UploadFiles from "./steps/upload-files";
import useApplyForm from "./use-apply-form";
import { FormProvider } from "react-hook-form";
import LoanDetails from "./steps/loan-details";
import "./index.scss";
import { useContext } from "react";
import { Context } from "../../../../context/StateContext";
import PrevButton from "./action-button/prev-button";
import { Watch } from "@material-ui/icons";

const LoanForm = () => {
  const { DarkModeStatus } = useContext(Context);

  const { handleSteps, onSubmit, methods, currentStep, isFilled, watch,setCurrentStep } =
    useApplyForm();
  const handleStepComponents = () => {
    // eslint-disable-next-line
    switch (currentStep) {
      case 1:
        return <BasicDetails isFilled={isFilled} />;
      case 2:
        return <LoanDetails />;
      case 3:
        {
          if(watch("loanAmount")>20000){
            return <UploadFiles />
          }
          else{
            setCurrentStep(currentStep+1);
          }
        }
      case 4:
        return <AccountDetails />;
    }
  };
  return (
    <div className={`d-flex form shadow position-relative ${DarkModeStatus ? "" : "light"}`}>
      <div className={`top-line ${DarkModeStatus ? "" : "light"}`}></div>
     <div className="w-50"></div>
      <Form className=" p-3 pe-5 w-50">
      

        <div className="d-flex justify-content-between">
          <p className={`fs-4 fw-bolder mt-5 ${DarkModeStatus ? "" : "light"}`}>
            <span>Fill up</span> the Form
          </p>
          <p className={`step-number mt-5 ${DarkModeStatus ? "" : "light"}`}>Step:{currentStep}/4</p>
        </div>
        <FormProvider {...methods}>
          <div className="form-steps">{handleStepComponents()}</div>
        </FormProvider>
        <div className="d-flex justify-content-between">
        <PrevButton
          handleSteps={handleSteps}
          currentStep={currentStep}
          onSubmit={onSubmit}
        />
        <NextButton
          onSubmit={onSubmit}
          handleSteps={handleSteps}
          currentStep={currentStep}
        />
        </div>
      </Form>
    </div>
  );
};

export default LoanForm;
