import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { Navigate, Outlet} from "react-router-dom";

const AdminPrivate=(props) =>{
  const token= jwtDecode(localStorage.getItem('corporatetoken'));
  const isAdmin = token.user.isAdmin;
  // const isAdmin=flse;
  return isAdmin ? <Outlet /> : <Navigate to={'/corporate/isAgent/agent'} />;
}

export default AdminPrivate;