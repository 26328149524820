import { useContext } from "react";
import { Button } from "reactstrap";
import { Context } from "../../../../../context/StateContext";
import "./index.scss";

const PrevButton = (props) => {
  const { DarkModeStatus } = useContext(Context);
  const { handleSteps, currentStep } = props;
  return (
    <>
        <Button
        className={`mt-4 border-1 border mb-4 next-button me-2 ${DarkModeStatus ? "" : "light"}`}
          onClick={() => handleSteps("prev")}
          disabled={currentStep === 1} 
        >
         Prev
        </Button>
     </>
  );
};
export default PrevButton;
{/* <button
         
         className=""
         onClick={() => handleSteps("prev")}
         disabled={currentStep === 1}
       >
          <span className="mdi mdi-chevron-left text-white fs-5"></span>
       </button> */}
