import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./index.scss";
import jwtDecode from "jwt-decode";
import { Context } from "../../../context/StateContext";
import { BsFillBrightnessLowFill } from "react-icons/bs";
import { CgDarkMode } from "react-icons/cg";
// import ocplLogo from "../../../img/ocpl logo.png";

const Navbar = (props) => {
  const { DarkModeStatus, darkModeHandler } = useContext(Context);
  let isAdmin;
  const [name, setName] = useState("");
  useEffect(() => {
    if (props.isCustomerLogin) {
      const token = jwtDecode(localStorage.getItem("customertoken"));
      setName(token.user.name);
    }
    if (props.isCorporateLogin) {
      const token = jwtDecode(localStorage.getItem("corporatetoken"));
      isAdmin = token.user.isAdmin;
    }
  });

  const navigate = useNavigate();
  return (
    <header className="d-flex justify-content-center " id="header">
      <div
        className={`menu-area ${DarkModeStatus ? "" : "light"}`}
        id="sticky-header"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg">
                <div className="logo">
                  <Link to="/">
                    <img
                      src="https://ocpl.tech/assets/ocpllogo-0d9039c9.webp"
                      className="img-fluid"
                      width="180"
                      alt="LOGO"
                    />
                  </Link>
                </div>
                <button
                  className="navbar-toggler bg-light"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className=" navbar-nav me-auto mb-2 mb-lg-0 ms-auto">
                    <li className={`nav-link `}>
                      <Link
                        to="/#"
                        className={`section-link ${
                          DarkModeStatus ? "" : "light"
                        }`}
                      >
                        Home
                      </Link>
                    </li>

                    <li className="nav-link">
                      <a
                        href="/#about"
                        className={`section-link ${
                          DarkModeStatus ? "" : "light"
                        }`}
                      >
                        About Us
                      </a>
                    </li>

                    <li className="nav-link">
                      <a
                        href="/#contact"
                        className={`section-link ${
                          DarkModeStatus ? "" : "light"
                        }`}
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <div className="toggle-btn">
                        {DarkModeStatus ? (
                          <CgDarkMode color="dark-grey" size={20} />
                        ) : (
                          <BsFillBrightnessLowFill color="grey" size={25} />
                        )}

                        <input
                          type="checkbox"
                          checked={DarkModeStatus}
                          onChange={darkModeHandler}
                          id="ToggleDarkMode"
                        />
                        <label htmlFor="ToggleDarkMode"></label>
                        {/* <p className={`${DarkModeStatus ? "" : "text-grey"}`}>Theme</p> */}
                      </div>
                    </li>

                    {/* <li>
                      <button onClick={darkModeHandler} className={`mode-btn ${DarkModeStatus? "":"light"}`}>
                        {DarkModeStatus ? (
                          <BsMoonStarsFill size={25} color="white" />
                        ) : (
                          <BsBrightnessHighFill size={30} />
                        )}
                      </button>
                    </li> */}
                  </ul>
                </div>

                <div className="header-action d-done d-md-block">
                  {/* <input type="checkbox" checked={DarkModeStatus} onChange={darkModeHandler} id="ToggleDarkMode" />
                      <label htmlFor="ToggleDarkMode"></label>
                       */}
                  <ul>
                    {/* <li className="header-btn"> 
                      <button className="btn">
                        <span className="mdi mdi-lightbulb-on fs-5"></span>
                      </button>
                    </li> */}

                    {!props.isCorporateLogin && !props.isCustomerLogin ? (
                      <>
                        <li className="header-btn">
                          <button
                            className="btn"
                            onClick={() => navigate("/customer/login")}
                          >
                            Customer
                          </button>
                        </li>
                        <li className="header-btn">
                          <button
                            className="btn"
                            onClick={() => {
                              navigate("/corporate/login");
                            }}
                          >
                            Corporate
                          </button>
                        </li>
                      </>
                    ) : (
                      <>
                        {props.isCorporateLogin ? (
                          <>
                            <li className="header-btn">
                              <button
                                className="btn"
                                onClick={() => {
                                  isAdmin
                                    ? navigate("/corporate/isAdmin/dashboard")
                                    : navigate("/corporate/isAgent/agent");
                                }}
                              >
                                dashboard
                              </button>
                            </li>
                            <li className="header-btn">
                              <button
                                className="btn"
                                onClick={() => {
                                  localStorage.removeItem("corporatetoken");
                                  props.setIsCorporateLogin(false);
                                  navigate("/");
                                }}
                              >
                                logout
                              </button>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="header-btn">
                              <button
                                className="btn"
                                onClick={() => navigate("/customer/apply-loan")}
                              >
                                Apply Loan
                              </button>
                            </li>
                            {/* <li className="header-btn container">
                              <div className="dropdown ">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                    Nikhil
                                </button>
                                <div
                                  className={`droppp dropdown-menu dropdown-menu-end ${
                                    !DarkModeStatus
                                      ? "bg-white"
                                      : "bg-secondary"
                                  } border-0 rounded-0 rounded-bottom m-0`}
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <Link
                                    className={`dropdown-item ${
                                      DarkModeStatus ? "text-white" : ""
                                    }`}
                                    onClick={() => {
                                      localStorage.removeItem("customertoken");
                                      props.setIsCustomerLogin(false);
                                      navigate("/");
                                    }}
                                  >
                                    Logout
                                  </Link>
                                  <Link
                                    className={`dropdown-item ${
                                      DarkModeStatus ? "text-white" : ""
                                    }`}
                                    onClick={() => {
                                      navigate("/customer/user-dashboard");
                                    }}
                                  >
                                    Dashboard
                                  </Link>
                                </div>
                              </div>
                            </li> */}
                            {/* <div className="header-btn">
                            <div className=" btn "> */}
                            <div className="dropdown">
                              <Link
                                to=""
                                className="dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                <img
                                  className="rounded-circle me-lg-2"
                                  src="https://www.shutterstock.com/image-vector/man-character-face-avatar-glasses-260nw-562077406.jpg"
                                  alt=""
                                  style={{ width: "40px", height: "40px" }}
                                />
                                <span className="d-none d-lg-inline-flex fs-5">
                                  {name}
                                </span>
                              </Link>
                              <div
                                className={`droppp dropdown-menu dropdown-menu-end ${
                                  !DarkModeStatus ? "bg-white" : "bg-secondary"
                                } border-0 rounded`}
                              >
                                <Link
                                  to=""
                                  className={`dropdown-item ${
                                    !DarkModeStatus ? "text-dark" : "text-grey"
                                  } `}
                                  onClick={() => {
                                    localStorage.removeItem("customertoken");
                                    props.setIsCustomerLogin(false);
                                    navigate("/");
                                  }}
                                >
                                  Logout
                                </Link>
                                {/* <Link to='' className={`dropdown-item ${DarkModeStatus ? "text-dark" : ""}`}>Settings</Link> */}
                                <Link
                                  to=""
                                  className={`dropdown-item ${
                                    !DarkModeStatus ? "text-dark" : "text-grey"
                                  } `}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/customer/user-dashboard");
                                  }}
                                >
                                  Dashboard
                                </Link>
                              </div>
                            </div>
                            {/* </div>
                            </div> */}
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
