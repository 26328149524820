import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "reactstrap";
import InputField from "../../../../common/fields/input-field";
import "./index.scss";

const AccountDetails = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const handleKeyPress = (event) => {
    if (event.target.selectionStart <= 3 && !/^[a-zA-Z]+$/.test(event.key)) {
      event.preventDefault();
    } else if (
      event.target.selectionStart >= 4 &&
      event.target.selectionStart < 11 &&
      !/^\d+$/.test(event.key)
    ) {
      event.preventDefault();
    } else if (event.target.value.length >= 11) {
      event.preventDefault();
    }
  };
  return (
    <Fragment>
      <Row>
        <p className="fs-4 fw-bolder">Account Details</p>
        <Col lg={6}>
          <InputField
            control={control}
            name="accountNumber"
            errors={errors}
            placeholder="Enter Account Number"
            label="Account Number"
            type="number"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="ifsc"
            errors={errors}
            placeholder="Enter IFSC"
            label="IFSC Code"
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="accountName"
            errors={errors}
            placeholder="Enter Name"
            label="Account Holder Name"
          />
        </Col>
      </Row>
    </Fragment>
  );
};
export default AccountDetails;
