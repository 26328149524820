import React, { useContext } from "react";
import "./index.scss";
import { Context } from "../../../../context/StateContext";


const WhyChooseUs = () => {
  const { DarkModeStatus } = useContext(Context);
  return (
    <section className={`choose-area pb-130 ${DarkModeStatus ? "" : "light"}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className={`sub-title ${DarkModeStatus ? "" : "light"}`}>
                Why Choose Us
              </span>
              <h2 className={`title ${DarkModeStatus ? "" : "light"}`}>
                Why Choose our OCPL
                <span> Finance</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row choose-active">
          <div className="col-lg-3 col-6">
            <div className={`choose-item ${DarkModeStatus ? "" : "light"}`}>
              <div className="choose-icon">
                <img
                  src="https://bigtech-react.netlify.app/static/media/choose_icon04.b10f3e20e7bd28a66b3bc682c0611101.svg"
                  alt="ICON"
                />
              </div>
              <div className="choose-content">
                <h2 className="title">
                  <a href="/">Trust and Security</a>
                </h2>
                <p className={`text ${DarkModeStatus ? "" : "light"}`}>
                  OCPL Finance prioritizes the trust and security of its users.
                  By integrating blockchain technology into its lending app,
                  OCPL Finance ensures transparency and immutability of
                  transactions. Blockchain's decentralized nature reduces the
                  risk of fraud and unauthorized access to user data.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className={`choose-item ${DarkModeStatus ? "" : "light"}`}>
              <div className="choose-icon">
                <img
                  src="https://bigtech-react.netlify.app/static/media/choose_icon04.b10f3e20e7bd28a66b3bc682c0611101.svg"
                  alt="ICON"
                />
              </div>
              <div className="choose-content">
                <h2 className="title">
                  <a href="/">Efficient and Fast</a>
                </h2>
                <p className={`text ${DarkModeStatus ? "" : "light"}`}>
                  With blockchain integration, OCPL Finance can streamline the
                  lending process and make it more efficient. Smart contracts
                  can automate various steps, such as loan approval, collateral
                  verification, and fund disbursement, reducing the need for
                  intermediaries and paperwork.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className={`choose-item ${DarkModeStatus ? "" : "light"}`}>
              <div className="choose-icon">
                <img
                  src="https://bigtech-react.netlify.app/static/media/choose_icon04.b10f3e20e7bd28a66b3bc682c0611101.svg"
                  alt="ICON"
                />
              </div>
              <div className="choose-content">
                <h2 className="title">
                  <a href="/">Lower Costs</a>
                </h2>
                <p className={`text ${DarkModeStatus ? "" : "light"}`}>
                  Traditional lending processes often involve multiple
                  intermediaries, which can lead to higher costs for borrowers.
                  By leveraging blockchain technology, OCPL Finance can
                  potentially eliminate or reduce the need for intermediaries,
                  resulting in lower costs for borrowers.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3  col-6">
            <div className={`choose-item ${DarkModeStatus ? "" : "light"}`}>
              <div className={`choose-icon ${DarkModeStatus ? "" : "light"}`}>
                <img
                  src="https://bigtech-react.netlify.app/static/media/choose_icon04.b10f3e20e7bd28a66b3bc682c0611101.svg"
                  alt="ICON"
                />
              </div>
              <div className="choose-content">
                <h2 className="title">
                  <a href="/">Transparent and Immutable</a>
                </h2>
                <p className={`text ${DarkModeStatus ? "" : "light"}`}>
                  OCPL Finance's integration of blockchain ensures transparency
                  in the lending process. All transactions and loan terms are
                  recorded on the blockchain, providing a transparent and
                  immutable record that can be verified by all parties involved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
