import React, { useContext, useState } from "react";
import "./index.scss";
import { NavLink, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Context } from "../../../../../context/StateContext";

function SideBar({ sideBarStatus }) {
  const { DarkModeStatus, darkModeHandler, openSideBar, handleSideBar } =
    useContext(Context);
  // console.log("working ", DarkModeStatus, openSideBar);
  const pathname = useLocation();
  // console.log(pathname);
  const token = jwtDecode(localStorage.getItem("corporatetoken"));
  const isAdmin = token.user.isAdmin;
  const name = token.user.name;
  return (
    <div
      className={`sidebar pe-4 pb-3  ${!DarkModeStatus ? "light" : ""} ${
        sideBarStatus ? "" : "open"
      }`}
    >
      <nav className="navbar  navbar-dark">
        <NavLink to="/" className="navbar-brand mx-2 mb-3">
          <h3 className="text-primary">
            <i className="fa fa-user-edit me-2"></i>
            <div className="d-inline ocpl-crm-logo">OCPL Finance</div>
          </h3>
        </NavLink>
        <div className="d-flex align-items-center ms-4 mb-4">
          <div className="position-relative">
            <img
              className="rounded-circle"
              src="https://www.shutterstock.com/image-vector/man-character-face-avatar-glasses-260nw-562077406.jpg"
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
          </div>
          <div className={`${!DarkModeStatus ? "text-dark" : ""} ms-3`}>
            <h6 className="mb-0">{name}</h6>
            <span>{isAdmin ? "Admin" : "Agent"}</span>
          </div>
        </div>
        <div className="navbar-nav w-100">
          {isAdmin ? (
            <>
              <NavLink
                to="/corporate/isAdmin/dashboard"
                className="nav-item nav-link"
              >
                <i className="fa fa-tachometer-alt me-2"></i>
                <span>Dashboard</span>
              </NavLink>
              <NavLink
                to="/corporate/isAdmin/clients"
                className="nav-item nav-link"
              >
                <i className="fa fa-th me-2"></i>Clients
              </NavLink>
              <NavLink
                to="/corporate/isAdmin/transaction"
                className="nav-item nav-link"
              >
                <i className="fa fa-keyboard me-2"></i>Transaction
              </NavLink>
            </>
          ) : (
            <>
              <NavLink
                to="/corporate/isAgent/agent"
                className="nav-item nav-link"
              >
                <i className="fa fa-th me-2"></i>Agent
              </NavLink>
              {/* <NavLink to="/corporate/isAgent/agentForm" className="nav-item nav-link">
            <i className="fa fa-keyboard me-2"></i>Agent Form
          </NavLink>  */}
            </>
          )}
          {/* <div className="nav-item dropdown">
            <NavLink
              to="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <i className="fa fa-laptop me-2"></i>Elements
            </NavLink>
            <div className="dropdown-menu bg-transparent border-0">
              <NavLink to="button.html" className="dropdown-item">
                Buttons
              </NavLink>
              <NavLink to="typography.html" className="dropdown-item">
                Typography
              </NavLink>
              <NavLink to="element.html" className="dropdown-item">
                Other Elements
              </NavLink>
            </div>
          </div> */}
        </div>
      </nav>
    </div>
  );
}

export default SideBar;
