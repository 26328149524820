import "./index.scss";
import { useContext } from "react";
import { Context } from "../../../../context/StateContext";

const Hero = () => {
  const { DarkModeStatus } = useContext(Context);

  return (
    <section className={`banner-bg ${DarkModeStatus ? "" : "light"}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className={`title ${DarkModeStatus ? "" : "light"}`}>
                Hassle Free Loan Technology Integrated with
                <span> Blockchain</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="banner-countdown-wrap text-center">
              <h2 className={`title ${DarkModeStatus ? "" : "light"}`}>
                Funds Transferred till Now...
              </h2>
              <div className="coming-time">
                <div
                  className={`time-count day ${DarkModeStatus ? "" : "light"}`}
                >
                  <span>00</span>
                  <div className={`text ${DarkModeStatus ? "" : "light"}`}>
                    Investors
                  </div>
                </div>
                <div
                  className={`time-count hour ${DarkModeStatus ? "" : "light"}`}
                >
                  <span>00</span>
                  <div className={`text ${DarkModeStatus ? "" : "light"}`}>
                    Debtors
                  </div>
                </div>
                <div
                  className={`time-count min ${DarkModeStatus ? "" : "light"}`}
                >
                  <span>00</span>
                  <div className={`text ${DarkModeStatus ? "" : "light"}`}>
                    Funds Transferred
                  </div>
                </div>
                <div
                  className={`time-count sec ${DarkModeStatus ? "" : "light"}`}
                >
                  <span>00</span>
                  <div className={`text ${DarkModeStatus ? "" : "light"}`}>
                    Users
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
