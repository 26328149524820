import React, { useContext } from "react";
import "./index.scss";
import Lottie from "react-lottie";
// import * as animationData from './'
import loader from '../../../../img/loader.json'
import { Context } from "../../../../context/StateContext";

const AboutUs = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { DarkModeStatus } = useContext(Context);
  return (
    <section
      className={`about-area pb-130 pt-130 ${DarkModeStatus ? "" : "light"}`}
      id="about"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            {/* <div
              className="about-img wow fadeInLeft"
              data-wow-delay=".2s"
              //   style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInLeft;"
            >
              <img
                src="https://bigtech-react.netlify.app/static/media/about_img01.92aec9a4de2f58631598.png"
                alt="ABOUT_US"
              />
            </div> */}
            <Lottie options={defaultOptions} speed={1.5}></Lottie>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content wow fadeInRight"
              data-wow-delay=".2s"
              //   style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInRight;"
            >
              <div className="section-title mb-30">
                <span
                  className={`sub-title ${
                    DarkModeStatus ? "" : "light"
                  } mt-lg-0 mt-5`}
                >
                  Who we are
                </span>
                <h2 className={`title ${DarkModeStatus ? "" : "light"}`}>
                  The World's <span>1st Blockchain Integrated </span>Loan
                  Application
                </h2>
              </div>
              <p className={`title-2 ${DarkModeStatus ? "" : "light"}`}>
                The World’s 1st ICO Platform That Offers Rewards and The
                platform helps investors to make easy to purchase and sell their
                tokens
              </p>
                <button className="header-btn about-us-btn border-1 border " href="/">
                  Become Member
                </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
