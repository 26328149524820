import React, { useContext } from "react";
import "./index.scss";
import { BiEnvelope, BiPhone } from "react-icons/bi";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PaymentCard from "./PaymentCard";

import { useCallback, useEffect, useState } from "react";
import "./index.scss";
import useRazorpay from "react-razorpay";

import jwtDecode from "jwt-decode";
import axios from "axios";
import http from "../../services/httpservice.js";

import { Context } from "../../../context/StateContext";

function UserDashboard() {
  let responseArray = [];
  let responseArray3 = [];
  const userDetails = jwtDecode(localStorage.getItem("customertoken"));
  console.log("userdetails", userDetails);
  const name = userDetails.user.name;
  const email = userDetails.user.email;
  const phone = userDetails.user.phone;
  let presentEmiAmount = "";
  let presentLoanAmount = "";
  let clientArray = []; //Array Defined to set the clientPaymentArray hook ultimately.
  const [clientPaymentArray, setClientPaymentArray] = useState([]);
  const [isClientInBc1, setIsClientInBc1] = useState(false); //we are setting this and using this in rendering other features of the application.

  const [loanAmount, setLoanAmount] = useState("");
  const [emiAmount, setEmiAmount] = useState("");
  const [loanTime, setLoanTime] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("Loan Not Applied");
  const serverList = [
    "http://192.168.29.61:5000/api",
    "http://192.168.29.61:5001/api",
    "http://192.168.29.61:5002/api",
  ];
  const [activeServer, setActiveServer] = useState(0);

  const loanDetails = async () => {
    try {
      const response = await axios.get(
        `${serverList[activeServer]}/blockchain1`
      );
      console.log("response of bc1", response);
      responseArray = response.data;
      console.log("bc1", responseArray);
      responseArray.map((element) => {
        if (element.data.decoData) {
          if (element.data.decoData.email === email) {
            if (element.data.decoData.loanAmount <= 20000) {
              setLoanAmount(element.data.decoData.loanAmount);
              setEmiAmount(element.data.decoData.emi);
              setLoanTime(element.data.decoData.loanTime);
              // console.log("yes we are here11111");
              setIsClientInBc1(true); //important to set this because it is controlling how to render other components.
              loanDetails1("less than");
              // console.log("which block is working11");
            } else {
              console.log("which block is working22");
              setLoanAmount(element.data.decoData.loanAmount);
              setEmiAmount(element.data.decoData.emi);
              setLoanTime(element.data.decoData.loanTime);
              loanDetails1("more than");
            }
          }
        }
      });
    } catch (err) {
      console.log("error", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
  };
  const loanDetails1 = async (value) => {
    console.log("value", value);
    //value here is we sending from second function because taking the value from the usestate hook was not working so we tried to send value through the prop.
    try {
      const response3 = await axios.get(
        `${serverList[activeServer]}/blockchain3`
      );
      // console.log("response", response);
      responseArray3 = response3.data;
      console.log(" bc3", responseArray3);
      const filteredBlockchain3 = responseArray3.filter(
        (element) => element.data.email === email
      );
      console.log("filteredbc3", filteredBlockchain3);
      if (filteredBlockchain3.length) {
        if (filteredBlockchain3[0].data.email) {
          if (
            value === "less than" &&
            filteredBlockchain3[0].data.email === email
          ) {
            setPaymentStatus("Payment Done by OCPL");
            clientArray.push(filteredBlockchain3[0].data);
          } else if (
            value === "less than" &&
            filteredBlockchain3[0].data.email !== email
          ) {
            setPaymentStatus("Application pending");
          } else if (
            value === "more than" &&
            filteredBlockchain3[0].data.email === email
          ) {
            // console.log("yes email matched");
            setPaymentStatus("Payment Done by OCPL");
            clientArray.push(filteredBlockchain3[0].data);
          } else if (
            value === "more than" &&
            filteredBlockchain3[0].data.email !== email
          ) {
            setPaymentStatus("Under Review");
          }
        }
      } else if (filteredBlockchain3.length === 0) {
        setPaymentStatus("Under Review");
      }
      // if (responseArray3.existingBlockchain) {
      //   responseArray3.existingBlockchain.map((element, index) => {
      //     if (element.data.email) {
      //       // console.log("checking the value", isClientInBc1);
      //       // console.log("checking the value sent value", value);
      //       // console.log("element", element.data.email);

      //       if (value === "less than" && element.data.email === email) {
      //         setPaymentStatus("Payment Done by OCPL");
      //         clientArray.push(element.data);
      //       } else if (value === "less than" && element.data.email !== email) {
      //         setPaymentStatus("Application pending");
      //       } else if (value === "more than" && element.data.email === email) {
      //         console.log("yes email matched");
      //         setPaymentStatus("Payment Done by OCPL");
      //         clientArray.push(element.data);
      //       } else if (value === "more than" && element.data.email !== email) {
      //         console.log("yes email doesnot  matched");

      //         if (paymentStatus === "Payment Done by OCPL") {
      //           console.log("log");
      //         }
      //       }
      //     }
      //     if (index === 0) {
      //       setPaymentStatus("Under Review");
      //     }
      //   });
      // }

      setClientPaymentArray(clientArray);
      console.log("the percentage infor", clientPaymentArray, loanTime);

      console.log(
        "client payment array to be transferred to payment card ",
        clientPaymentArray
      );
    } catch (err) {
      console.log("in the error block of api calling blockchain3");
      console.log("error", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
  };

  useEffect(() => {
    loanDetails();
  }, [activeServer]);
  //the code for fetching the current user data from the response blockchain.
  // try {
  //   for (let i = 0; i < responseArray; i++) {
  //     if (responseArray[i].data.data.email === name) {
  //       presentEmiAmount = responseArray[i].data.data.presentEmi;
  //       presentLoanAmount = responseArray[i].data.data.loanAmount;
  //       console.log("presentloan", presentLoanAmount);
  //     }
  //   }
  // } catch (err) {
  //   console.log("forlooperror", err);
  // }

  const Razorpay = useRazorpay();
  const { DarkModeStatus } = useContext(Context);

  // const handlePayment = useCallback(() => {
  //   const options = {
  //     key: "rzp_test_u1Rc5nug9j7roz",
  //     amount: 10000 * 100,
  //     name: "OCPL Finance",
  //     description: "OCPL Finance",
  //     image: "img/logo.png", // COMPANY LOGO
  //     handler: function (response) {
  //       console.log("Returning now");
  //       alert(
  //         "Payment Id " + response.razorpay_payment_id + " : Payment successful"
  //       );
  //       setPaymentStatus("Completed");
  //       console.log("Payment Status: completed");
  //       // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
  //     },
  //     theme: {
  //       color: "#15b8f3",
  //     },
  //   };

  //   const rzpay = new Razorpay(options);
  //   rzpay.open();
  // }, [Razorpay]);
  const apiEndpoint = `${serverList[activeServer]}/mine3`;

  const handlePayment = async () => {
    console.log("clicked");
    let trn = "";
    const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const length = 15;
    for (let i = 0; i < length; i++) {
      trn += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    // console.log(emiAmount);
    // console.log(trn);
    // console.log(email);
    let today = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = today.toLocaleDateString(undefined, options);
    // console.log(formattedDate);
    try {
      const resp = await http.post(apiEndpoint, {
        emiAmount,
        trn,
        email,
        formattedDate,
      });
      console.log("bc3 response on clicking pay now by user", resp);
      // window.location = "/customer/user-dashboard";
      loanDetails();
    } catch (err) {
      console.log("error", err.message);
      if (err.message === "Network Error") {
        console.log("in catch block");
        setActiveServer((activeServer + 1) % serverList.length);
      }
      const resp = await http.post(apiEndpoint, {
        emiAmount,
        trn,
        email,
        formattedDate,
      });
    }
  };

  const emiLeft = 4;
  let isPaymentDone = false;
  const percentage = Number(
    ((clientPaymentArray.length - 1) / loanTime) * 100
  ).toFixed(0);

  if (percentage === "100") {
    console.log("percentage is 100");
    isPaymentDone = true;
  }

  // const percentage = 66;
  const isLoanSanctioned = true;

  return isLoanSanctioned ? (
    <section className={`user-dashboard ${DarkModeStatus ? "" : "light"}`}>
      <div className="container">
        <div className="description">
          <h1>Hi {name},</h1>
          <p>Welcome to your Dashboard</p>
        </div>
        <div className="row justify-content-between">
          <div className="col-12 col-lg-5">
            <div className="box">
              <div className="user-details d-flex">
                <img
                  className="profile-pic"
                  src="https://previews.123rf.com/images/metelsky/metelsky1809/metelsky180900233/109815470-man-avatar-profile-male-face-icon-vector-illustration.jpg"
                  alt="profile"
                />
                <div className="details">
                  <h4>{name}</h4>
                </div>
              </div>
              <div className="contact-info">
                <BiEnvelope size={20} color="#7188BE" />

                <p className="email">{email}</p>
                <span></span>
                <BiPhone size={20} color="#7188BE" />
                <p className="mobile">+91-{phone}</p>
              </div>
              <hr />
              <div className="remarks">
                <p>Personal Rating</p>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-4">
            <div className="box">
              <div className="data">
                <p>Application Status</p>{" "}
                <p
                  className={`payment-status ${
                    paymentStatus === "Payment Done by OCPL"
                      ? "bg-success"
                      : "bg-danger"
                  } p-2`}
                >
                  {isPaymentDone ? "Loan Closed" : paymentStatus}
                </p>
              </div>
              <p className="fs-3 ">
                {isPaymentDone
                  ? null
                  : paymentStatus === "Under Review"
                  ? null
                  : emiAmount
                  ? `₹ ${emiAmount}`
                  : null}
              </p>
              {paymentStatus === "Payment Done by OCPL" ? (
                <button
                  className={`btn btn-info p-2 w-50 ${
                    percentage >= 100 ? "d-none" : ""
                  }`}
                  data-bs-toggle="modal"
                  data-bs-target="#reg-modal"
                  // disabled={percentage>=100}
                  onClick={() => {
                    handlePayment();
                  }}
                >
                  Pay Now
                </button>
              ) : paymentStatus === "Loan Not Applied" ||
                isPaymentDone ||
                "Under Review" ? null : null}
              <hr />

              {paymentStatus === "Payment Done by OCPL" ? (
                <p>Total Loan Amount</p>
              ) : paymentStatus === "Loan Not Applied" ||
                "Under Review" ? null : null}
              <p className="fs-3 ">
                {isPaymentDone
                  ? null
                  : paymentStatus === "Under Review"
                  ? null
                  : loanAmount
                  ? `₹ ${loanAmount}`
                  : null}
              </p>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box">
              <p className="fw-bolder">Re-Payment Progress</p>
              {paymentStatus === "Payment Done by OCPL" ? (
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  className="circular-progressBar"
                />
              ) : paymentStatus === "Loan Not Applied" ||
                "Under Review" ? null : null}
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="box">
              <p className="fw-bolder">Payment History</p>
              <hr />
              <div className="payment-history">
                <PaymentCard clientPaymentArray={clientPaymentArray} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {paymentStatus === "Pending" ? null : (
        <div className="modal fade" id="reg-modal" arial-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5>abc</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <h3>Payment Already Done!</h3>
                <img src="https://cdn-icons-png.flaticon.com/512/9957/9957506.png" />
                <h4>No worry for 1 month</h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  ) : (
    <section className={`empty-userDashboard ${DarkModeStatus ? "" : "light"}`}>
      <div className="container">
        <div className="description">
          <h1>Hi {name},</h1>
          <p>Welcome to User Dashboard</p>
          <h3>Application Status : Submitted</h3>
        </div>
        <div className="message p-4">
          <h2>Loan Not Sanctioned Yet</h2>
          <img
            src="https://cdn-icons-png.flaticon.com/512/10055/10055530.png"
            alt="icons"
            className="my-5"
          />
          <p>Please wait for next 24 hours</p>
        </div>
      </div>
    </section>
  );
}

export default UserDashboard;
