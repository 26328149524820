import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Container, TextField, Button, Typography } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Context } from "../../../../context/StateContext";

const schema = Joi.object({
  email: Joi.string().email().required(),
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#030b15",
  },
  light: {
    backgroundColor: "rgb(218, 220, 222)",
  },
  icon: {
    fontSize: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: theme.breakpoints.values.sm,
    padding: theme.spacing(4),
    // border: `1px solid ${theme.palette.grey[400]}`,
    marginTop: "100px",
    borderRadius: "20px",
    backgroundColor: "#0b1d33",
  },
  lightform: {
    borderRadius: "20px",
    boxShadow: "rgb(29, 29, 29, 40%) 0px 0px 16px",
    backgroundColor: "white",
  },
  textField: {
    marginBottom: "16px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  button: {
    marginTop: theme.spacing(2),
    padding: "8px",
    fontSize: "20px",
  },
}));

function CorporateForgotPassword() {
  const [serverList, setServerList] = useState([
    "http://192.168.29.61:5000",
    "http://192.168.29.61:5001",
    "http://192.168.29.61:5002",
  ]);
  const [isRequestSent, setIsRequestSent] = useState(false);
  // const [activeServer, setActiveServer] = useState(0);
  const [verifiedServer, setVerifiedServer] = useState("");
  // console.log("verified servers", verifiedServer);
  useEffect(() => {
    const sendApiRequest = async (url) => {
      try {
        const response = await fetch(url);
        console.log(`resonse from url ${url}`, response);
        // const data = await response.json();
        // console.log(`data from url ${url}`, data);

        if (response.status === 200) {
          console.log(`API request sent to ${url}`);
          setIsRequestSent(true); // set isRequestSent to true if API call is successful
          return true; // return true if API call is successful
        } else {
          // console.error(`Error in API response from ${url}: ${data.error}`);
          return false; // return false if API call returns an error
        }
      } catch (error) {
        // console.error(`Error sending API request to ${url}: ${error}`);
        return false; // return false if there's an error sending the API request
      }
    };

    const sendRequestToServers = async () => {
      for (const url of serverList) {
        const isServerHealthy = await sendApiRequest(url);
        if (isServerHealthy) {
          setVerifiedServer(url);
          break; // stop iterating through servers if a healthy server is found
        }
      }
    };

    sendRequestToServers();
  }, [serverList]);
  // console.log("verified servers2", verifiedServer);
  const { DarkModeStatus } = useContext(Context);
  const navigate = useNavigate();
  const classes = useStyles();
  const [data, setData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };
  const validate = (data) => {
    const { error } = schema.validate(data, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    error.details.forEach((detail) => {
      errors[detail.path[0]] = detail.message;
    });
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(data);
    setErrors(validationErrors || {});
    if (validationErrors) return;
    fetch(`${verifiedServer}/corporate/forgot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: data.email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          // alert(data.message);
          toast.success(data.message);
          navigate("/");
        } else {
          toast.warning(data.error);
          // alert(data.error);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
        toast.error("invalid credentials");
      });
  };

  return (
    <Container
      maxWidth={false}
      className={`${classes.container} ${DarkModeStatus ? "" : classes.light}`}
    >
      <form
        className={`${classes.form} ${DarkModeStatus ? "" : classes.lightform}`}
        onSubmit={handleSubmit}
      >
        <LockOutlinedIcon color="primary" className={classes.icon} />
        <Typography variant="h5" gutterBottom>
          Forgot your password?
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Enter your email address below and we'll send you a link to reset your
          password.
        </Typography>
        <TextField
          name="email"
          id="email"
          label="Email"
          variant="outlined"
          required
          fullWidth
          value={data.email}
          onChange={handleChange}
          className={classes.textField}
        />
        {errors.email && (
          <small className="text-danger">
            Email should be in proper Email format.
          </small>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          className={classes.button}
        >
          Send Reset Link
        </Button>
      </form>
    </Container>
  );
}

export default CorporateForgotPassword;
