import LoanForm from "./form";
import "./index.scss";
import { Context } from "../../../context/StateContext";
import React, { useContext } from "react";


const ApplyLoan = () => {
    const { DarkModeStatus } = useContext(Context);

  return (
    <section className={`loan-form ${DarkModeStatus ? "" : "light"}`}>
      <div className="container">
        
     <LoanForm/>
     </div>
    </section>
  );
};

export default ApplyLoan;
