import { Route, Routes, useLocation } from "react-router";
import { useState, useEffect } from "react";
// import isLoginIn from "./components/isLogin";
import "./App.scss";
import ApplyLoan from "./components/views/apply-loan";
import Home from "./components/views/home/home";
import LoanHome from "./components/views/loan-home";
import Navbar from "./components/common/Navbar";
import SignUp from "./components/views/customer/signup";
import SignIn from "./components/views/customer/login";
import ForgotPassword from "./components/views/customer/forgot-password";
import PrivateRoute from "./components/privateRoutes/PrivateRoute";
import RestrictedRoute from "./components/privateRoutes/RestrictedRoute";
import NotFound from "./components/common/Error";  
import StateContext from "./context/StateContext";
import UserDashboard from "./components/views/userDashboard";
import CorporatePrivateRoute from "./components/privateRoutes/CorporatePrivateRoute";
import CorporateRestrictedRoute from "./components/privateRoutes/CorporateRestrictedRoute";
import CorporateSignUp from "./components/views/corporate/corporate-signup";
import CorporateSignIn from "./components/views/corporate/corporate-login";
import CorporateForgotPassword from "./components/views/corporate/corporate-forgot-password";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Transaction from "./components/views/corporate/corporate-dashboard/transaction";
import Clients from "./components/views/corporate/corporate-dashboard/clients";
import Agent from "./components/views/corporate/corporate-dashboard/agent";
import AgentForm from "./components/views/corporate/corporate-dashboard/agentForm";
import AdminPrivate from "./components/privateRoutes/AdminPrivate";
import AgentPrivate from "./components/privateRoutes/AgentPrivate";
import DashBoard from "./components/views/corporate/corporate-dashboard/dashboard";
import Footer from "./components/views/corporate/corporate-dashboard/footer";
import { toast } from "react-toastify";

import NavBar from "./components/views/corporate/corporate-dashboard/navbar";
export default function App() {
  const serverList = [
    "http://192.168.29.61:5000",
    "http://192.168.29.61:5001",
    "http://192.168.29.61:5002",
  ];
  const [activeServer, setActiveServer] = useState(0);
  const pathname = useLocation();
  const [isCorporateLogin, setIsCorporateLogin] = useState(false);
  const [isCustomerLogin, setIsCustomerLogin] = useState(false);
  // useEffect(() => {
  //   fetch();
  // }, [activeServer]);
  const isCutomerLoginIn = (token) => {
    if (token) {
      fetch(`${serverList[activeServer]}/customer/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.valid === true) setIsCustomerLogin(true);
          else setIsCustomerLogin(false);
        })
        .catch((error) => {
          console.log("error", error.message);
          toast.error("invalid credentials");
          if (error.message === "Failed to fetch") {
            console.log("in catch block");
            setActiveServer((activeServer + 1) % serverList.length);
          }
        });
    } else {
      setIsCustomerLogin(false);
    }
  };
  const isCorporateLoginIn = (token) => {
    if (token) {
      fetch(`${serverList[activeServer]}/corporate/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.valid === true) setIsCorporateLogin(true);
          else setIsCorporateLogin(false);
        })
        .catch((error) => {
          console.log("error", error.message);
          toast.error("invalid credentials");
          if (error.message === "Failed to fetch") {
            console.log("in catch block");
            setActiveServer((activeServer + 1) % serverList.length);
          }
        });
    } else {
      setIsCorporateLogin(false);
    }
  };

  // useEffect(() => {
  //   const customertoken = localStorage.getItem("customertoken");
  //   const corporatetoken = localStorage.getItem("corporatetoken");
  //   if (customertoken) {
  //     isCutomerLoginIn(customertoken);
  //   } else if (corporatetoken) {
  //     isCorporateLoginIn(corporatetoken);
  //   }
  // }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <StateContext>
        {pathname.pathname === "/corporate/isAdmin/dashboard" ||
        pathname.pathname === "/corporate/isAdmin/clients" ||
        pathname.pathname === "/corporate/isAdmin/transaction" ||
        pathname.pathname === "/corporate/isAgent/agent" ||
        pathname.pathname.substring(0, 29) ===
          "/corporate/isAgent/agentForm/" ? (
          <NavBar />
        ) : (
          <Navbar
            isCorporateLogin={isCorporateLogin}
            isCustomerLogin={isCustomerLogin}
            setIsCorporateLogin={setIsCorporateLogin}
            setIsCustomerLogin={setIsCustomerLogin}
          />
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/customer"
            element={
              <PrivateRoute
                isCustomerLogin={isCustomerLogin}
                isCorporateLogin={isCorporateLogin}
              />
            }
          >
            <Route path="apply-loan" element={<LoanHome />} />
            <Route path="loan-form" element={<ApplyLoan />} />
            <Route path="user-dashboard" element={<UserDashboard />} />
          </Route>
          <Route
            path="/customer"
            element={
              <RestrictedRoute
                isCustomerLogin={isCustomerLogin}
                isCorporateLogin={isCorporateLogin}
              />
            }
          >
            <Route
              path="login"
              element={<SignIn setIsCustomerLogin={setIsCustomerLogin} />}
            />
            <Route path="signup" element={<SignUp />} />
            <Route path="forgot" element={<ForgotPassword />} />
          </Route>
          <Route
            path="/corporate"
            element={
              <CorporateRestrictedRoute
                isCorporateLogin={isCorporateLogin}
                isCustomerLogin={isCustomerLogin}
              />
            }
          >
            <Route
              path="login"
              element={
                <CorporateSignIn setIsCorporateLogin={setIsCorporateLogin} />
              }
            />
            <Route path="signup" element={<CorporateSignUp />} />
            <Route path="forgot" element={<CorporateForgotPassword />} />
          </Route>
          <Route
            path="/corporate"
            element={
              <CorporatePrivateRoute
                isCorporateLogin={isCorporateLogin}
                isCustomerLogin={isCustomerLogin}
              />
            }
          >
            <Route path="isAdmin" element={<AdminPrivate />}>
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="clients" element={<Clients />} />
              <Route path="transaction" element={<Transaction />} />
            </Route>
            <Route path="isAgent" element={<AgentPrivate />}>
              <Route path="agent" element={<Agent />} />
              <Route path="agentForm/:clientName" element={<AgentForm />} />
            </Route>
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
        {pathname.pathname === "/corporate/isAdmin/dashboard" ||
        pathname.pathname === "/corporate/isAdmin/clients" ||
        pathname.pathname === "/corporate/isAdmin/transaction" ||
        pathname.pathname === "/corporate/isAgent/agent" ||
        pathname.pathname.substring(0, 29) ===
          "/corporate/isAgent/agentForm/" ? (
          <Footer />
        ) : null}
      </StateContext>
    </>
  );
}
