import React, { useContext } from "react";
import { Context } from "../../../context/StateContext";
import "./index.scss";

function PaymentCard(props) {
  const { DarkModeStatus } = useContext(Context);
  console.log("client payment array", props.clientPaymentArray);

  return (
    <div className="table-responsive">
      <table
        className={`table  ${
          DarkModeStatus
            ? "text-white "
            : "text-black table-bordered border-dark "
        }`}
      >
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">TRN Number</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.clientPaymentArray.map((element, index) => (
            <tr key={index}>
              <td>{element.formattedDate}</td>
              <td>{element.trn}</td>
              <td>{element.emiAmount || element.loanAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentCard;

{
  /* <tr>
            <td>20/11/20</td>
            <td>Paid first installment </td>
            <td>₹ 20000</td>
          </tr>
          <tr>
            <td>20/11/21</td>
            <td>Paid Second installment </td>
            <td>₹ 20000</td>
          </tr>
          <tr>
            <td>20/11/22</td>
            <td>Paid Third installment </td>
            <td>₹ 20000</td>
          </tr> */
}
