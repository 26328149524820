import AboutUs from "./about-us";
import OcplLogo from "./canvas-particles";
import ContactUs from "./contact-us";
import Hero from "./hero";
import WhyChooseUs from "./why-choose-us";

const Home = () => {
  return (
    <div>
      <OcplLogo />
       <Hero />
       <AboutUs />
      <WhyChooseUs/>
      <ContactUs />  
    </div>
  );
};

export default Home;
