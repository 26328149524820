import React, { createContext, useState } from "react";

export const Context = createContext();

function StateContext({ children }) {
  const [DarkModeStatus, setDarkModeStatus] = useState(true);

  const darkModeHandler= ()=>{
    setDarkModeStatus(!DarkModeStatus);
  }

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  return (
    <Context.Provider value={{ DarkModeStatus, darkModeHandler, openSideBar, handleSideBar }}>
      {children}
    </Context.Provider>
  );
}

export default StateContext;
