import { useContext } from "react";
import { Button } from "reactstrap";
import { Context } from "../../../../../context/StateContext";
import "./index.scss";

const NextButton = (props) => {
  const { DarkModeStatus } = useContext(Context);

  const {  currentStep, onSubmit } = props;
  return (
      <Button
        className={`mt-4 border-1 border mb-4 next-button ${DarkModeStatus ? "" : "light"}`}
        onClick={onSubmit}
      >
        {currentStep === 4 ? "Submit" : "Next"}
      </Button>
  );
};
export default NextButton;
