import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "reactstrap";
import InputField from "../../../../common/fields/input-field";
import "./index.scss";

const BasicDetails = (props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { isFilled } = props;
  const handleKeyPress = (event) => {
    if (event.target.selectionStart <= 4 && !/^[a-zA-Z]+$/.test(event.key)) {
      event.preventDefault();
    } else if (event.target.selectionStart >= 5 && event.target.selectionStart < 8 && !/^\d+$/.test(event.key)) {
      event.preventDefault();
    }
    else if (event.target.selectionStart ===9  && !/^[a-zA-Z]+$/.test(event.key)) {
      event.preventDefault();
    }
    else if(event.target.value.length >= 10){
      event.preventDefault();
    }
  };
  return (
    <Fragment>
      <Row>
        <Col lg={6}>
          <InputField
            control={control}
            name="name"
            isDisabled={isFilled}
            errors={errors}
            placeholder="Enter your Full Name"
            label="Full Name"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="mobile"
            errors={errors}
            isDisabled={isFilled}
            placeholder="Enter your Mobile"
            label="Mobile Number"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="email"
            isDisabled={isFilled}
            errors={errors}
            placeholder="Enter your Email ID"
            label="Email ID"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="dob"
            type="date"
            errors={errors}
            placeholder="Enter your Date of Birth"
            label="Date of Birth"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="city"
            errors={errors}
            placeholder="Enter your City"
            label="City"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="pincode"
            errors={errors}
            type="number"
            placeholder="Enter your Pincode"
            label="Pincode"
          />
        </Col>
        <Col lg={6}>
          <select className="form-select">
            <option value="" disabled="">
              Employment Type
            </option>
            <option value="salaried">Salaried</option>
            <option value="business">Own Business</option>
          </select>
        </Col>
        <Col lg={6}>
          <select className="form-select">
            <option value="" disabled="">
              Residence Type
            </option>
            <option value="owned">Owned</option>
            <option value="rented">Rented</option>
          </select>
        </Col>
        <Col lg={12}>
          <InputField
            control={control}
            name="residenceAddress"
            errors={errors}
            placeholder="Enter your Residence Address"
            label="Residence Address"
          />
        </Col>
        <p className="fs-4 fw-bolder ">
          <span>Document</span> Details
        </p>
        <Col lg={6}>
          <InputField
            control={control}
            name="pan"
            errors={errors}
            placeholder="Enter your Pan Number"
            label="Pan Number"
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="adhaar"
            errors={errors}
            placeholder="Enter your Aadhar Number"
            label="Aadhar Number"
            maxLength="12"
            type="Number"
          />
        </Col>
      </Row>
    </Fragment>
  );
};
export default BasicDetails;
