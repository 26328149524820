import React from 'react';

const NotFound = () => {
  return (
    <div style={{marginTop: "400px",marginLeft: '100px'}}>
      <h1>404 Not Found</h1>
    </div>
  );
};

export default NotFound;