import { Controller } from "react-hook-form";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import "./index.scss";
import React, { useContext } from "react";
import { Context } from "../../../context/StateContext";

const InputField = (props) => {
  const { label, name, control, type = "text", errors = {}, placeholder, isDisabled= false,maxLength=40,onKeyPress } = props;
    const { DarkModeStatus } = useContext(Context);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <FormGroup className={`form-floating mt-1 ${DarkModeStatus ? "" : "light"}`}>
            <Input
              type={type}
              invalid={Boolean(errors[name])}
              value={value}
              disabled={isDisabled}
              onChange={onChange}
              placeholder={placeholder}
              maxLength={maxLength}
              onKeyPress={onKeyPress}
            />
            <Label for="floatingInput">
              {label}
            </Label>
            {errors[name] && (
            <FormFeedback role="alert">
              {errors[name].message}
            </FormFeedback>
          )}
          </FormGroup>

         
        </>
      )}
    />
  );
};
export default InputField;
