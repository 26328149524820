import React, { useContext } from "react";
import { Context } from "../../../../context/StateContext";
import "./index.scss";

const ContactUs = () => {
  const { DarkModeStatus } = useContext(Context);
  return (
    <section
      className={`contact-area pt-70 pb-110 ${DarkModeStatus ? "" : "light"}`}
      id="contact"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-70">
              <span className={`sub-title ${DarkModeStatus ? "" : "light"}`}>
                Contact
              </span>
              <h2 className="title">
                <span>Contact</span>{" "}
                <div className={`text ${DarkModeStatus ? "" : "light"}`}>
                  OCPL Finance
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div className="contact-info-wrap">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="contact-info-item">
                <div className="icon">
                  <span
                    className={`icon-background ${
                      DarkModeStatus ? "" : "light"
                    }`}
                  ></span>
                  <i className="fas fa-envelope"></i>
                </div>
                {/* <div className="content"> */}
                <p className={`${DarkModeStatus ? "" : "light"} inner-text`}>
                  info@ocpl.tech
                </p>
                {/* </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="contact-info-item">
                <div className="icon">
                  <span
                    className={`icon-background ${
                      DarkModeStatus ? "" : "light"
                    }`}
                  ></span>
                  <i className="fas fa-phone"></i>
                </div>
                {/* <div className="content"> */}
                <p className={`${DarkModeStatus ? "" : "light"} inner-text`}>
                  +91 9555-555-801
                </p>
                {/* </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="contact-info-item-last">
                <div className="icon">
                  <span
                    className={`icon-background ${
                      DarkModeStatus ? "" : "light"
                    }`}
                  ></span>
                  <i className="fas fa-address-card"></i>
                </div>
                {/* <div className="content"> */}
                <p className={`${DarkModeStatus ? "" : "light"} inner-text`}>
                  Karol Bagh, New Delhi
                </p>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={`contact-form-wrap ${DarkModeStatus ? "" : "light"} `}>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-grp">
                  <input type="text" placeholder="Enter Your Name" required />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-grp">
                  <input type="text" placeholder="Enter Your Email" required />
                </div>
              </div>
            </div>
            <div className="form-grp">
              <textarea
                name="message"
                placeholder="Enter Your Message"
                rows="4"
                cols="50"
              ></textarea>
            </div>
            <div className="submit-btn text-center">
              <button className="about-us-btn" type="submit">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
