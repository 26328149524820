import { Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Col, FormFeedback, Row } from "reactstrap";
import InputField from "../../../../common/fields/input-field";
import RadioField from "../../../../common/fields/radio-fields";
import "./index.scss";

const LoanDetails = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useFormContext();
  const [interest, setInterest] = useState("");

  const calculateEmiAndInterest = () => {
    const p = watch("loanAmount");
    const rate = 12;
    const n = watch("loanTime");
    const r = rate / 12 / 100;
    const numerator = p * r * Math.pow(1 + r, n);
    const denominator = Math.pow(1 + r, n) - 1;
    const emiValue = numerator / denominator;
    const totalInterest = emiValue * n - p;
    setInterest(totalInterest.toFixed(2));
    setValue("emi", emiValue.toFixed(2));
  };
  return (
    <Fragment>
      <Row>
        <Col lg={6}>
          <InputField
            control={control}
            name="loanAmount"
            errors={errors}
            placeholder="Enter Loan Amount"
            label="Loan Amount"
            type="number"
          />
        </Col>
        <Col lg={6}>
          <InputField
            control={control}
            name="loanPurpose"
            errors={errors}
            placeholder="Enter Loan Purpose"
            label="Loan Purpose"
          />
        </Col>
        <p className="fs-4 fw-bolder mt-5">
          <span>Duration</span> of Loan
        </p>
        <Col lg={6}>
          <RadioField
            control={control}
            name="loanTime"
            id="3"
            label="3 Months"
            errors={errors}
          />
        </Col>
        <Col lg={6}>
          <RadioField
            control={control}
            name="loanTime"
            id="6"
            label="6 Months"
            errors={errors}
          />
        </Col>
        {errors["loanTime"] && (
          <FormFeedback role="alert" className="d-block">
            {errors["loanTime"].message}
          </FormFeedback>
        )}

        <small className="text-danger">
          Calculate your emi and interest before Submitting the form
        </small>
        <div className="col-4">
          <div
            className="btn border-1 border mt-3 btn-info "
            onClick={calculateEmiAndInterest}
          >
            Calculate Your EMI
          </div>
        </div>
        {watch("emi") && (
          <span>
            EMI: <span>{watch("emi")}</span>
          </span>
        )}
        {interest && (
          <>
            <span>
                Interest: <span>{interest}</span>
            </span>
            <span>
              Interest Rate: 12%
            </span>
          </>
          
          
        )}
      </Row>
    </Fragment>
  );
};
export default LoanDetails;
