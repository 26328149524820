import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { Navigate, Outlet} from "react-router-dom";

const AgentPrivate=(props) =>{
    const token= jwtDecode(localStorage.getItem('corporatetoken'));
    const isAdmin=token.user.isAdmin;
    // const isAdmin=false;
    return !isAdmin ? <Outlet /> : <Navigate to={'/corporate/isAdmin/dashboard'} />;
}

export default AgentPrivate;