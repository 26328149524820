import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context/StateContext";
import "./index.scss";

const Transaction = () => {
  const { DarkModeStatus, openSideBar } = useContext(Context);
  const [transactionArray, setTransactionArray] = useState([]);
  const serverList = [
    "http://192.168.29.61:5000/api",
    "http://192.168.29.61:5001/api",
    "http://192.168.29.61:5002/api",
  ];
  const [activeServer, setActiveServer] = useState(0);

  const transactionDetails = async () => {
    try {
      const response = await axios.get(
        `${serverList[activeServer]}/blockchain3`
      );
      const responseArray = response.data;
      // console.log(
      //   "response array transaction details",
      //   responseArray.existingBlockchain
      // );
      setTransactionArray(responseArray.filter((element, index) => index > 0));
    } catch (err) {
      console.log("error", err.message);
      if (err.message === "Network Error") {
        setActiveServer((activeServer + 1) % serverList.length);
      }
    }
  };

  useEffect(() => {
    transactionDetails();
    //eslint-disable-next-line
  }, [activeServer]);
  return (
    <div
      className={`content ${!DarkModeStatus ? "light" : ""} ${
        openSideBar ? "" : "open"
      }`}
    >
      <div className="container-fluid pt-4 px-4">
        <div
          className={`${
            !DarkModeStatus ? "bg-white" : "bg-secondary"
          } text-center rounded p-4`}
        >
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="mb-0">Recent Transaction</h6>
          </div>
          {transactionArray.map((curElem, index) => {
            return (
              <div
                className={`card mb-4 ${
                  !DarkModeStatus ? "bg-darkgrey" : "bg-dark"
                }`}
                key={index}
              >
                <div className="card-body ">
                  <div className="row">
                    <div className="col text-start ">
                      <p
                        className={`d-block fs-6 ${
                          !DarkModeStatus ? "text-black" : "text-white"
                        }`}
                      >
                        Email:
                        <small className="fs-6 fw-bold d-inline">
                          {" "}
                          &nbsp; &nbsp; {curElem.data?.email}
                        </small>
                      </p>
                      <p
                        className={`d-block fs-6 ${
                          !DarkModeStatus ? "text-black" : "text-white"
                        }`}
                      >
                        Transaction Number:
                        <small className="fs-6 fw-bold d-inline">
                          {" "}
                          &nbsp; &nbsp;{curElem.data?.trn}
                        </small>
                      </p>
                    </div>
                    <div className="col text-start">
                      <p
                        className={`d-block fs-6 ${
                          !DarkModeStatus ? "text-black" : "text-white"
                        }`}
                      >
                        Date:
                        <small className="fs-6 fw-bold d-inline">
                          {" "}
                          &nbsp; &nbsp; {curElem.data?.formattedDate}
                        </small>
                      </p>
                      <p
                        className={`d-block fs-6 ${
                          !DarkModeStatus ? "text-black" : "text-white"
                        }`}
                      >
                        Amount:
                        <small className="fs-6 fw-bold d-inline">
                          {" "}
                          &nbsp; &nbsp;{" "}
                          {curElem.data?.loanAmount || curElem.data?.emiAmount}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    //         );
    //       }}
    //     </Context.Consumer>
    //     <Footer />
    //     </Context.Provider>
    // </div>
  );
};

export default Transaction;
