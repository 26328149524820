// import { Link } from "react-router-dom";
// import LoanForm from "./form";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { Context } from "../../../context/StateContext";
import "./index.scss";

const LoanHome= () => {
  const { DarkModeStatus } = useContext(Context);
  const navigate = useNavigate();
  return (
    <section className="apply-loan">
      <div className={`bg-overlay ${DarkModeStatus? " " : "light"}`}></div>
      <h3 className="loan-home-title">
        Get Your Loan transferred
        <br />
        in 3 steps
      </h3>
      <button className="about-us-btn my-5" onClick={()=>navigate("/customer/loan-form")}>GET STARTED</button>
      <div className="container">
        <div className="row text-white">
          <div className="col-lg-4 col-4">
            <div className={`card d-flex justify-content-center align-items-center flex-column p-1 ${DarkModeStatus? " " : "light"} `}>
              <img src="https://wordpress-theme.spider-themes.net/banca/wp-content/uploads/2021/10/icon-1.png" alt="ICON"/>
              <h3 className="fw-bolder my-4">Check Eligibility</h3>
              <p>
                Select your loan amount, answer a few questions and get instant
                loan amount
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-4">
          <div className={`card d-flex justify-content-center align-items-center flex-column p-1 ${DarkModeStatus? " " : "light"} `}>
              <img src="https://wordpress-theme.spider-themes.net/banca/wp-content/uploads/2021/10/icon-1.png" alt="ICON"/>
              <h3 className="fw-bolder my-4">Submit Documents</h3>
              <p>
                Select your loan amount, answer a few questions and get instant
                loan amount
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-4">
          <div className={`card d-flex justify-content-center align-items-center flex-column p-1 ${DarkModeStatus? " " : "light"} `}>
              <img src="https://wordpress-theme.spider-themes.net/banca/wp-content/uploads/2021/10/icon-1.png" alt="ICON"/>
              <h3 className="fw-bolder my-4">Loan transferred</h3>
              <p>
                Select your loan amount, answer a few questions and get instant
                loan amount
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoanHome;
