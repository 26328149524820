import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AccountDetailsSchema,
  basicDetailSchema,
  LoanDetailsSchema,
  UploadFilesSchema,
} from "./utils/schema";
import { useNavigate } from "react-router-dom";
import http from "../../../services/httpservice.js";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const defaultValues = {
  name: "",
  mobile: "",
  email: "",
  dob: "",
  city: "",
  pincode: "",
  residenceAddress: "",
  pan: "",
  adhaar: "",
  loanAmount: "",
  loanPurpose: "",
  accountNumber: "",
  ifsc: "",
  accountName: "",
  loanTime: "",
  emi: "",
};
const useApplyForm = () => {
  const [serverList, setServerList] = useState([
    "http://192.168.29.61:5000",
    "http://192.168.29.61:5001",
    "http://192.168.29.61:5002",
  ]);
  const [isRequestSent, setIsRequestSent] = useState(false);
  // const [activeServer, setActiveServer] = useState(0);
  const [verifiedServer, setVerifiedServer] = useState("");
  // console.log("verified servers", verifiedServer);
  useEffect(() => {
    const sendApiRequest = async (url) => {
      try {
        const response = await fetch(url);
        console.log(`resonse from url ${url}`, response);
        // const data = await response.json();
        // console.log(`data from url ${url}`, data);

        if (response.status === 200) {
          console.log(`API request sent to ${url}`);
          setIsRequestSent(true); // set isRequestSent to true if API call is successful
          return true; // return true if API call is successful
        } else {
          // console.error(`Error in API response from ${url}: ${data.error}`);
          return false; // return false if API call returns an error
        }
      } catch (error) {
        // console.error(`Error sending API request to ${url}: ${error}`);
        return false; // return false if there's an error sending the API request
      }
    };

    const sendRequestToServers = async () => {
      for (const url of serverList) {
        const isServerHealthy = await sendApiRequest(url);
        if (isServerHealthy) {
          setVerifiedServer(url);
          break; // stop iterating through servers if a healthy server is found
        }
      }
    };

    sendRequestToServers();
  }, [serverList]);
  // console.log("verified servers2", verifiedServer);

  const apiEndpoint = `${verifiedServer}/api/mine1`;

  const validationSchema = {
    1: basicDetailSchema,
    2: LoanDetailsSchema,
    3: UploadFilesSchema,
    4: AccountDetailsSchema,
  };
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema[currentStep]),
  });
  const { watch, setValue } = methods;
  const [isFilled, setIsFilled] = useState(false);
  const userDetails = jwtDecode(localStorage.getItem("customertoken"));
  const name = userDetails.user.name;
  const email = userDetails.user.email;
  const mobile = userDetails.user.phone;
  const accountName = userDetails.user.name;
  // const name = "Akash Verma";
  // const email = "akashverma4102@gmail.com";
  // const mobile = "9818032019";
  // const accountName = "Akash Verma";

  useEffect(() => {
    if (name || email || mobile) {
      methods.reset({ ...defaultValues, name, email, mobile, accountName });
      setIsFilled(true);
    }
    //eslint
  }, []);
  useEffect(() => {
    setValue("pan", watch("pan").toUpperCase());
    // eslint-disable-next-line
  }, [watch("pan")]);
  const handleSteps = (action = "") => {
    if (action === "prev") {
      if (watch("loanAmount") < 20000) {
        setCurrentStep(currentStep - 2);
      } else {
        setCurrentStep(currentStep - 1);
      }
    } else if (action === "next") setCurrentStep(currentStep + 1);
  };
  const onSubmit = async () => {
    methods.handleSubmit(async (data) => {
      handleSteps("next");
      console.log(data);
      if (currentStep === 4) {
        const decoData = {
          ...data,
          loanAmount: Number(data.loanAmount),
          mobile: Number(data.mobile),
          pincode: Number(data.pincode),
          loanAmount: Number(data.loanAmount),
          loanTime: Number(data.loanTime),
          emi: Number(data.emi),
        };
        console.log(decoData);
        navigate("/customer/apply-loan");

        try {
          const resp = await http.post(apiEndpoint, { decoData });
          console.log("resp in customer apply loan endpoint", resp);
          toast.success("Form Submitted!");
          // alert("Form Submitted!");
        } catch (err) {
          console.log("error", err.message);
        }
      }
    })();
  };

  return {
    handleSteps,
    onSubmit,
    currentStep,
    methods,
    isFilled,
    watch,
    setCurrentStep,
  };
};
export default useApplyForm;
