import img_0 from '../../../../img/1.png';
// import img_1 from '../../img/2.png';
// import img_2 from '../../img/3.png';
// import img_3 from '../../img/4.png';
// import img_4 from '../../img/5.png';
// import img_5 from '../../img/6.png';
// import img_6 from '../../img/7.png';
// import img_7 from '../../img/8.png';
// import img_8 from '../../img/9.png';
// import img_9 from '../../img/10.png';
// import img_10 from '../../img/11.png';
// import { Context } from '../../context/StateContext';
// import { useContext } from 'react';





// const { DarkModeStatus } = useContext(Context);
export const flowersArray = [ 
        img_0, 
        // img_10
]